(function ($) {

    $(document)

        .on('init.cross_selling', '.cross_selling-field', function (e) {
            $(this).select2({
                // placeholder: $(this).attr('placeholder'),
                minimumInputLength: 1,
                ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
                    url: Routing.generate('admin_cross_selling_search'),
                    dataType: 'json',
                    data: function (term) {
                        return {
                            keyword: term,
                        };
                    },
                    results: function (data) { // parse the results into the format expected by Select2.
                        // since we are using custom formatting functions we do not need to alter remote JSON data
                        return { results: data };
                    }
                },
                formatInputTooShort : function (term, minLength) {
                    return 'Veuillez tapper au minimum ' + minLength + ' caractères';
                },
                formatSearching : function () {
                    return 'Recherche en cours...';
                },
                formatNoMatches : function () {
                    return 'Aucun résultat';
                },
                formatResult: function (data) {
                    return data.list;
                },
                formatSelection: function (data) {
                    return data.select;
                },
                initSelection : function (element, callback) {
                    var id=$(element).val();
                    if (id!=="") {
                        $.ajax(Routing.generate('admin_cross_selling_search'), { data: { keyword: id, }, })
                            .done(function(data) { callback(data[0]); })
                        ;
                    }
                },
                dropdownCssClass: "bigdrop", // apply css that makes the dropdown taller
                escapeMarkup: function (m) { return m; }
            })
        })

        .on('add.collection', '[data-prototype]', function (e) { $('.cross_selling-field', $(this).children().first()).trigger('init.cross_selling') })

        .ready(function (e) { $('.cross_selling-field').trigger('init.cross_selling'); })

    ;

})(jQuery);
