(function(){
    if ($('body').data('route') == 'admin_catalog_manage') {
        yepnope({
            'test': Modernizr.draganddrop,
            'yep' : '/bundles/faroscatalog/libs/jquery.sortable.js',
            'nope': '//ajax.googleapis.com/ajax/libs/jqueryui/1.10.3/jquey-ui.min.js',
            'complete': function() {
                $('.sortable').sortable({
                    handle: '.handle'
                });
            }
        });

        $('#catalogTable').on('sortupdate', '.sortable', function(e, ui) {
            var target = $(e.target),
                parent = target.data('parent'),
                catalog = target.parents('#catalogTable').data('catalog'),
                position = [],
                item = $(ui.item),
                children = item.is('.item-product')? $('.item-product', target) : $('.item-category', target),
                route = item.is('.item-product')? 'admin_catalog_hierarchy_sort_product' : 'admin_catalog_hierarchy_sort_hierarchy'
            ;

            for (var i = 0; i < children.length; i++) {
                position.push($(children[i]).data('identifier'));
            };

            $.post(Routing.generate(route, {'id': parent, 'catalog': catalog}), {'position': position});
        });


        var loader = '<div class="alert alert-block alert-info"><i class="icon-spinner icon-spin"></i> Chargement des informations</div>';
        var currentCateg = 'root';
        var catalog = $('#catalogTable').data('catalog');

        $('#catalogTable').on('click', '.sortable .item-category', function(event){
            if ($(event.target).is('li')) {
                event.preventDefault();

                var parent = $(this),
                    identifier = $(parent).data('identifier'),
                    cell    = $(this).parents('td').first(),
                    depth = parseInt($(cell).data('depth'), 10)
                    heading = $('thead th[data-depth="'+depth+'"]')
                ;


                if ($(parent).is('.active')) {
                    currentCateg = $('[data-depth="'+(depth-1)+'"] .active').data('identifier');

                    $(parent).removeClass('active');
                    cell.removeClass('ancestor');
                    heading.removeClass('ancestor');
                    $('[data-depth="'+depth+'"]').removeClass('span2').addClass('working');
                    $('[data-depth="'+depth+'"]').nextUntil('.repository-column').remove();
                } else {
                    currentCateg = parent.data('identifier');

                    $('[data-depth]').removeClass('ancestor working').addClass('col-md-2');
                    for (var i = depth; i > 0; i--) {
                        $('[data-depth="'+i+'"]').addClass('ancestor');
                    };
                    $("[data-depth]:not('.ancestor') .active").removeClass('active');
                    $('[data-depth="'+(depth)+'"] .active').removeClass('active');
                    parent.addClass('active');

                  if ($('[data-depth="' + (depth + 1) + '"]').length === 0) {
                        $(heading).after('<th data-depth="'+(depth+1)+'" class="working">Niveau '+(depth +1)+'</th>');
                        $(cell).after('<td data-depth="'+(depth+1)+'" class="working">'+loader+'</td>');
                    } else {
                        $('[data-depth="'+(depth+1)+'"]').removeClass('col-md-2').addClass('working');
                    }

                    $('[data-depth="'+(depth+1)+'"]').nextUntil('.repository-column').remove();

                    loadWorkspace(identifier);
                }
            }
        });

        var queryRequest = null;
        var searchLoader = '<li><i class="fa fa-spinner fa-spin"></i> Chargement des informations</li>';
        $('#category_repository input').on('keyup', function(event) {

            if (queryRequest !== null)  {
                queryRequest.abort();
            }

            var categories = $('tbody td.working .item-category'),
                existingCateg = [$('.item-category.selected').data('category')]
            ;

            for (var i = 0; i < categories.length; i++) {
                existingCateg.push($(categories[i]).data('category'))
            };

            $('#category_repository .nav').html(searchLoader);


            queryRequest = $.get(Routing.generate('admin_product_category_search'), { 'q': $(this).val(), 'exclude': existingCateg.join(',')}, function (data) {
                var html = '';
                if (data.length > 0) {
                    for (var i = 0; i < data.length;  i++) {
                        html += '<li data-category="'+data[i]['id']+'"><a href="#" class="tooltip-trigger" title="'+data[i]['title']+ '">'+data[i]['name']+'</a></li>';
                    };
                } else {
                    html = '<li>Aucun résultat</li>';
                }

                $('#category_repository .nav').html(html);
                $('#category_repository .nav .tooltip-trigger').tooltip();
            });
        });

        $('#product_repository input').on('keyup', function(event) {

            if (queryRequest !== null)  {
                queryRequest.abort();
            }

            var categories = $('tbody td.working .item-product'),
                existingCateg = [$('.item-product.selected').data('product')]
            ;

            for (var i = 0; i < categories.length; i++) {
                existingCateg.push($(categories[i]).data('product'))
            };
            $('#product_repository .nav').html(searchLoader);
            queryRequest = $.get(Routing.generate('admin_product_search'), { 'q': $(this).val(), 'exclude': existingCateg.join(',')}, function (data) {
                var html = '';
                if (data.length > 0) {
                    for (var i = 0; i < data.length;  i++) {
                        html += '<li data-product="'+data[i]['id']+'"><a href="#" class="tooltip-trigger" title="'+data[i]['title']+ '">'+data[i]['name']+'</a></li>';
                    };
                } else {
                    html = '<li>Aucun résultat</li>';
                }

                $('#product_repository .nav').html(html);
                $('#product_repository .nav .tooltip-trigger').tooltip();
            });
        });


        $('#category_repository').on('click', '.nav li', function(event) {
            event.preventDefault();

            if ($(this).not('disabled')) {
                var categId = $(this).data('category')
                if ($('.working li[data-category="' + categId + '"]').length > 0) {
                    $(this).fadeTo(100,0.1, function(){
                        $(this).fadeTo(100, 1);
                    })
                } else {
                    $(this).toggleClass('active');
                }

                $('#category_repository button').prop('disabled', !$('#category_repository .nav .active').length > 0);
            }
        });

        $('#category_repository').on('click', 'button', function(event) {

            var categories = $('#category_repository .active'),
                post_data  =  { 'categories' : [] },
                $this      = $(this)
            ;

            for (var i = 0; i < categories.length; i++) {
                post_data.categories.push($(categories[i]).data('category'));
            };

            $('i', $this).prop('class', 'fa fa-spinner fa-spin');

            $.post(Routing.generate('admin_catalog_add_hierarchy', { 'id': catalog, 'parent': currentCateg }), post_data)
                .always(function() {
                    $('#category_repository .nav .active').removeClass('active');
                    $('#category_repository button').prop('disabled', true);

                    loadWorkspace(currentCateg, function() {
                        $('i', $this).prop('class', 'fa fa-plus-square');
                    });
                })
                .fail(function(xhr){
                    bootbox.dialog(xhr.responseText, [{
                        "label" : "OK",
                    }], {
                        'classes': 'modal-catalog-errors'
                    });
                })
            ;
        });


        $('#product_repository').on('click', '.nav li', function(event) {
            event.preventDefault();

            var productId = $(this).data('product')
            if ($('tbody .working div[data-product="' + productId + '"]').length > 0) {
                $(this).fadeTo(100,0.1, function(){
                    $(this).fadeTo(100, 1);
                })
            } else {
                $(this).toggleClass('active');
            }

            $('#product_repository button').prop('disabled', !$('#product_repository .nav .active').length > 0);
        });

        $('#product_repository').on('click', 'button', function(event) {
            var products = $('#product_repository .active'),
                post_data  =  { 'products' : [] },
                $this      = $(this)
            ;

            for (var i = 0; i < products.length; i++) {
                post_data.products.push($(products[i]).data('product'));
            };

            $('i', $this).prop('class', 'fa fa-spinner fa-spin');

            $.post(Routing.generate('admin_catalog_add_hierarchy', { 'id': catalog, 'parent': currentCateg }), post_data)
                .always(function() {
                    $('#product_repository .nav .active').removeClass('active');
                    $('#product_repository button').prop('disabled', true);
                    loadWorkspace(currentCateg, function() {
                        $('i', $this).prop('class', 'fa fa-plus-square');
                    });
                })
                .fail(function(xhr){
                    bootbox.dialog(xhr.responseText, [{
                        "label" : "OK",
                    }], {
                        'classes': 'modal-catalog-errors'
                    });
                })
            ;
        });

        $('#catalogTable').on('click','.toggle-publication, .toggle-main', function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();

            var $this = $(this),
                identifier = $this.parents('li').data('identifier'),
                parent = $this.parents('ul').data('parentIdentifier')
            ;

            $('i', $this).prop('class', 'fa fa-spinner fa-spin');

            $.get($this.prop('href'), function() {

                $.get(Routing.generate('admin_catalog_get_children', {'id': catalog, 'category': parent}), function(data) {
                    $('[data-identifier="'+identifier+'"]').replaceWith($('[data-identifier="'+identifier+'"]', data).first());
                });
            });
        });

        $('#catalogTable').on('click','.delete', function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            var $this = $(this);

            if (!$this.hasClass('disabled')) {
                $('i', $this).prop('class', 'fa fa-spinner fa-spin');


                $.get($this.prop('href'), function(){
                    $this.parents('.catalog-item').remove();
                });
            }
        });

        $('#catalog').show();
        $('.loader').remove();
    }

    function loadWorkspace(identifier, callback) {
        var workspace = $('tbody td.working');

        workspace.html(loader);

        $.get(Routing.generate('admin_catalog_get_children', {'id': catalog, 'category': identifier}) , function(data) {

            workspace.html(data);

            $('.sortable', workspace).sortable({
                'forcePlaceholderSize': true
            });

            var products = $('[data-product]', workspace),
                categories = $('[data-category]', workspace)
            ;


            $('#product_repository [data-product]').removeClass('disabled');
            for (var i = 0; i < products.length; i++) {
                id = $(products[i]).data('product');
                $('#product_repository [data-product="'+ id +'"]').addClass('disabled');
            };

            $('#category_repository [data-category]').removeClass('disabled');
            for (var i = 0; i < categories.length; i++) {
                id = $(categories[i]).data('category');
                $('#category_repository [data-category="'+ id +'"]').addClass('disabled');
            };

            if (callback) {
                callback.call(data);
            };
        });
    }
})();
