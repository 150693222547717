(function ($, undefined) {

    $(document)
        .on('click', '[data-image-preview]', function(evt) {
            evt.preventDefault();
            var $this = $(this),
                path = $($this.data('image-preview')).val(),
                location,  width, height;

            if ('' != path) {
                    width    = $this.data('image-preview-width')  || 1024;
                    height   = $this.data('image-preview-height') || 768;
                    location = Routing.generate('faros_media_preview', {
                            'path'   : path,
                            'width'  : width,
                            'height' : height,
                        }, true);
                    w = window.open(location, 'image-preview', "fullscreen=no,menubar=no,status=no,titlebar=no,toolbar=no,width="+ width +",height="+ height +"");
                ;
                // Gives the focus to the new window
                w.focus();
            };
        })
    ;
})(jQuery);
