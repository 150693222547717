(function ($, undefined) {

    $(document).on('click', '[data-window-location]', function(evt) {
        evt.preventDefault();
        var $this = $(this),
            w     = window.open(
            $this.data('window-location'),
            $this.data('window-name'),
            $this.data('window-properties')
        )
        // Gives the focus to the new window
        w.focus();
    });

})(jQuery);
