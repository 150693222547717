(function ($, undefined) {

    $(document)
        .on('add.collection', '[data-prototype]', function(evt, insertMode) {
            evt.stopPropagation();
            var $collection = $(this),
                index = $collection
                    .data('next-index', 1 + (
                        $collection.data('next-index') || ($collection.children().length)
                    ))
                    .data('next-index'),
                prototype = new RegExp($collection.data("prototype-name") || "__name__", "g"),
                $entry = $($collection.data('prototype').replace(prototype, index))
            ;

            if ('append' == insertMode) {
                // Append and scroll to added element
                $collection.append($entry);
                $entry.one('add.collection-entry', function (evt) {
                    $('html, body').animate({
                        scrollTop: $(this).offset().top - parseInt($('body').css('padding-top'))
                    }, 200);
                });
            } else {
                // Prepend by default
                $collection.prepend($entry);
            }

            $entry.trigger('add.collection-entry');
        })
        .on('delete.collection', '[data-prototype]', function(evt, $item) {
            if (undefined !== $item) {
                $item.remove();
            }
        })
        .on('reindex.collection', function(evt, collection) {
            var $collection = $(collection),
                $collectionNamePrefix = $collection.data('name-prefix');

            $collection.children('[id^='+$collection.prop('id')+']').each(function (idx, element) {
                var $element = $(element),
                    oldIndex = parseInt($element.data('collection-position'));

                if (idx != oldIndex) {
                    // Change data-collection-position
                    $element.data('collection-position', idx);
                    // Defines old name  prefix
                    var oldNamePrefix = $collectionNamePrefix + '[' + oldIndex + ']';
                    var newNamePrefix = $collectionNamePrefix + '[' + idx + ']';
                    // Change all inside element names
                    $element.find('[name^="'+oldNamePrefix+'"]').each(function (subIdx, subElement) {
                        var $subElement = $(subElement);

                        var newElementName = $subElement.prop('name').replace(oldNamePrefix, newNamePrefix);
                        $subElement.prop('name', newElementName);
                    })
                }
            });
        })
    ;

    $(document).on('click', '[data-collection][data-action="add"]', function() {
        var $this       = $(this),
            $collection = $($this.data('collection'))
        ;
        if (!$collection.is('[data-prototype]')) {
            $collection = $collection.find('[data-prototype]');
        }

        var insertMode = $this.data('insert-mode');

        $collection.trigger('add.collection', insertMode);
    });

    $(document).on('click', '[data-target][data-action="delete"]', function() {
        var $this       = $(this),
            $collection = $this.closest('[data-prototype]')
        ;
        $collection.trigger('delete.collection', [$($this.data('target'))]);
    });

    $(document).on('click', '[data-target][data-action^="move-"]', function() {
        var $this       = $(this),
            $target     = $($this.data('target')),
            $collection = $this.closest('[data-prototype]')
        ;

        if ('move-up' == $this.data('action')) {
            $before = $target.prev();
            if ($before.length > 0) {
                $before.before($target);
            }
        } else if ('move-down' == $this.data('action')) {
            $after = $target.next();
            if ($after.length > 0) {
                $after.after($target);
            }
        }

        // Scrolls to moved element
        $('html, body').animate({
            scrollTop: $('#' + $target.attr('id')).offset().top - parseInt($('body').css('padding-top'))
        }, 200);

        $collection.trigger('reindex.collection', $collection);
    });

})(jQuery);
