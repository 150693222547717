(function ($, undefined) {
    $(document).ready(function() {
        if ($('#batch-actions').length > 0) {
            var $batch = $('#batch-actions');

            // Enables row selection
            $('.datatables').addClass('DTTT_selectable');
            $('.datatables tbody').on('click', 'tr', function () {
                $(this).toggleClass('active');
                $batch.trigger('update-selection');
            });

            $batch
                .on('click', '[data-selection]', function(evt) {
                    evt.preventDefault();

                    var $entry    = $(this),
                        selection = $entry.data('selection');

                    $batch
                        .trigger('select-' + selection)
                        .trigger('update-selection');
                    ;
                })
                .on('click', '[data-action]', function (evt) {
                    evt.preventDefault();
                    var $entry       = $(this),
                        url          = $entry.prop('href'),
                        selection    = $batch.find('#batch-selection').val()
                    ;

                    if (!/^$/.test(selection)) {
                        window.location.replace(url + (/\?/.test(url) ? '&' : '?') + 'selection=' + encodeURIComponent(selection));
                    }
                })
            ;

            $('#batch-actions')
                .on('update-selection', function (evt) {
                    var $selection = $batch.find('#batch-selection'),
                        $datatable = $('.datatables'),
                        api        = $datatable.dataTable().api(),
                        ids        = $.map($('tbody tr.active', $datatable), function(row) {
                            var data = api.row(row).data();
                            return data.id || null;
                        })
                    ;
                    $selection.val(ids.join(','));
                })
                .on('select-page', function (evt) {
                    $('.datatables tbody tr').addClass('active');
                })
                .on('select-none', function (evt) {
                    $('.datatables tbody tr').removeClass('active');
                })
            ;
        }

    });
})(jQuery);
