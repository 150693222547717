(function ($, undefined) {

    $(document)

        .on('customize.select2', '.select2.shop-coupon-codes', function (evt, options, done) {
            $.extend(options, {
                openOnEnter: false,
                dropdownCss: {visibility: 'hidden'}
            });
            done(options);
        })

        .on('change', '.shop-coupon-constraint-validator', function (e) {
            var validatorId = $(this).prop('id');
            // Hide and disable all relative attributes
            $('[data-constraint-validator-id="'+validatorId+'"]').hide();
            $('[data-constraint-validator-id="'+validatorId+'"] :input').prop('disabled', true);
            // Show and enable all attributes for validator value
            var $fgToShow = $('[data-constraint-validator-id="'+validatorId+'"][data-constraint-attributes-value="'+$(this).val()+'"]');
            $fgToShow.show();
            $fgToShow.find(':input').prop('disabled', false);
                $fgToShow.find('.select2').each(function (idx, element) {
                $(element).trigger('init.select2');
            });
        })
        
    ;

})(jQuery);