(function ($, undefined) {

    $(document)

        .on('post-init.select2', '.select2', function (evt) {
            var $this = $(this);

            if ($this.is('.sortable')) {
                $this.select2("container").find("ul.select2-choices").sortable({
                    containment: 'parent',
                    start: function() { $this.select2("onSortStart"); },
                    update: function() { $this.select2("onSortEnd"); }
                });
            }
        })

        /**
         * Computes movement to perform and trigger event
         *
         * @param  Event   evt      jQuery.Event triggered
         *
         * @return jQuery
         */
        .on('init.select2', '.select2', function (evt) {
            var $this = $(this),
                options = {},
                defer = $.Deferred()
            ;

            defer
                .promise(options)
                .pipe(function (options) {
                    var df = $.Deferred();

                    $.extend(
                        options,
                        {
                            'width': '100%',
                            'allowClear': !$this.prop('required'),
                            'formatLoadMore' : function(pageNumber) { return Translator.trans('select2.load_more'); },
                            'formatSelectionTooBig' : function(maxSize) { return Translator.transChoice('select2.selection_too_big', maxSize, {'maxSize': maxSize }); },
                            'formatInputTooLong' : function(term, maxLength) { return Translator.transChoice('select2.input_too_long', maxLength, {'maxLength': maxLength }); },
                            'formatInputTooShort' : function(term, minLength) { return Translator.transChoice('select2.input_too_short', minLength, {'minLength': minLength }); },
                            'formatSearching' : function() { return Translator.trans('select2.searching'); },
                            'formatNoMatches' : function(term) { return Translator.trans('select2.no_matches'); }
                        }
                    );

                    $this.trigger('customize.select2', [ options, df.resolve ]);
                    return df.promise();
                })
                .then(function (options) {
                    $this.select2(options);

                    $this.trigger('post-init.select2');
                })
            ;

            defer.resolve(options);
        })

        .on('customize.select2', '.select2', function (evt, options, done) {
            done(options);
        })

        .on('customize.select2', '.select2[data-tags]', function (evt, options, done) {
            var $this = $(this);

            var tags = $this.data('tags'),
                format = function(item) {
                    return tags[item.id] || item.id;
                }
            ;

            $.extend(
                options,
                {
                    "tags": Object.keys(tags),
                    "tokenSeparators": $this.data('tokenSeparators') ? $this.data('tokenSeparators') : [",", " "],
                    "formatSelection": format,
                    "formatResult": format,
                    "matcher": function(term, text, option) {
                        return tags[text].toUpperCase().indexOf(term.toUpperCase())>=0;
                    }
                },
                true
            );

            done(options);
        })

        .on('customize.select2', '.select2[data-provide="entity-autocomplete"]', function (evt, options, done) {
            var $this = $(this);

            var properties = $this.data('property').split(','),
                format = function(item) {
                    var string = '';

                    for (var i = 0; i < properties.length; i++) {
                        if (item.hasOwnProperty(properties[i])) {
                            string += item[properties[i]] + ' ';
                        };
                    };

                    return string || item.text;
                }
            ;

            $.extend(options, {

                minimumInputLength: $this.data('minLength'),
                id: $this.data('id'),
                multiple: $this.data('multiple'),
                ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
                    url: $this.data('url'),
                    dataType: 'json',
                    type: 'POST',
                    data: function (term, page) {
                        var inputs = $this.parents('form').serializeArray(),
                            formData = {}
                        ;

                        for (var i in inputs) {
                            formData[inputs[i].name] = inputs[i].value;
                        }

                        return $.extend({}, formData, $this.data('autocompleteData'), {
                            'autocomplete-term': term, // search term
                            'length': $this.data('limit'),
                            'start': (page-1) * $this.data('limit'),
                            'page': page,
                            'field': $this.prop('name')
                        });
                    },
                    results: function (data, page) {
                        var result = {},
                            group_property = null
                        ;

                        if ((group_property = $this.data('groupBy'))) {
                            var hierarchicalData = {};

                            result.results = [];

                            for (var i = 0; i < data.data.length; i++) {
                                if (!hierarchicalData[data.data[i][group_property]]) {
                                    hierarchicalData[data.data[i][group_property]] = [];
                                }
                                hierarchicalData[data.data[i][group_property]].push(data.data[i]);
                            }

                            for (var property in hierarchicalData) {
                                if (hierarchicalData.hasOwnProperty(property)) {
                                    result.results.push({
                                        'text': property,
                                        'children': hierarchicalData[property]
                                    });
                                }
                            }
                        } else {
                            result = { results: data.data };
                        }

                        result.more = (page * $this.data('limit')) < data.recordsFiltered;

                        return result;
                    },
                },
                initSelection: function(element, callback) {
                    if (!element.val()) {
                        return;
                    }

                    var obj = {};

                    obj[properties[0]] = '<i class="fa fa-spin fa-spinner"></i> ' + Translator.trans('select2.searching');

                    callback(obj);

                    var data = {
                            'autocomplete-id': $(element).val(), // search terms
                        },
                        result = []
                    ;

                    $.post($this.data('url'), data, function(result) {
                        var initData = {};

                        if (result.recordsFiltered == 0) {
                            initData[properties[0]] = '<i class="fa fa-exclamation"></i> ' + Translator.trans('select2.no_matches');
                        } else {
                            initData = $this.data('multiple') ? result.data : result.data[0];
                        }

                        callback(initData);
                    });

                },
                formatResult: format,
                formatSelection: format
            });

            done(options);
        })

        .on('customize.select2', '#rule_route.select2', function (evt, options, done) {
          $.extend(
            options, {
              "matcher": function (params, data, $option) {
                if ($.trim(params) === '') {
                  return true;
                }

                if (data.indexOf(params) > -1) {
                  return true;
                }

                var regex = $option.data('path').replaceAll(/{[^{]*}/g, '[^\/]+').replaceAll('/', '\\/') + '$';
                if (params.match(regex)) {
                  return true;
                }

                return false;
              }
            },
            true
          );

          done(options);
        })
    ;

    $(document).on('add.collection', '[data-prototype]', function () {
        // Works only for collection prepend insert-mode
        $('.select2', $(this).children().first()).trigger('init.select2');
    });

    $('.select2').trigger('init.select2');

})(jQuery);
